.my-footer {
  background-color: var(--main-up-keep-primary);
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
}

.container-footer {
  margin: 0 12em;
  padding: 4em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-footer img {
  margin-bottom: 2.5em;
  width: 7em;
  height: 2.5em;

}

.nav-footer {
  flex-direction: row;
  border-top: 2px solid #4d5c6c;
  border-bottom: 2px solid #4d5c6c;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2em 0;
}

.nav-footer li {
  display: inline;
  justify-content: center;
  align-items: center;
}

.nav-footer li a {
  font-size: .9em;
  text-decoration: none;
  padding: 1em;
  list-style: none;
  color: var(--main-up-keep-secondary);
  font-weight: bold;
}

.copyright-text {
  display: inline-block;
  color: #a8a8a8;
  margin-top: 1em;
  text-align: center;
}

/* media Querys */
@media screen and (max-width: 960px) {
  .my-footer {
    width: 100vw;
  }

  .container-footer {
    margin: 0 5em;
  }

  .nav-footer {
    display: flex;
    padding: 1.2em 0;
    flex-wrap: wrap;
  }

  .copyright-text {
    text-align: center;
  }
}