.create-open-charge {
  background-color: #FFF;
  width: 800px;
  height: auto;
  border-radius: 5px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

.button-remove-container {
  width: 800px;
  margin-bottom: 10px;
}

.description-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.text-area-description {
  text-align: center;
}

.price-duration-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.container-info-charges {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 4px;
}

.text-description {
  width: 85px;
  text-align: left;
  margin: 2px 0 2px 0;
}

.open-charge-input {
  width: 40%;
  border: none;
  border-radius: 5px;
  background-color: #E6E6FA;
}

.duration-time-select {
  width: 90px;
}

.remove-open-charge-button {
  width: 100px;
  height: 35px;
  background-color: #6c757d;
  border-radius: 5px;
  border: 0;
  margin: 3px;
  padding: 1px;
  color: #FFF;
  text-align: center;
}

@media (max-width: 900px) {
  .create-open-charge {
    width: 80%;
    flex-direction: column;
  }

  .button-remove-container {
    width: 80%;
  }

  .price-duration-container {
    margin-top: 15px;
    width: 100%;
    flex-direction: row;
  }

  .description-container {
    width: 90%;
  }

  .text-area-description {
    width: 90%;
  }

  .text-description {
    margin: 2px 0 2px 0;
  }
}


@media (max-width: 699px) {
  .container-info-charges {
    width: 80%;
  }
}
