.schedule-service-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  margin: auto;flex-direction: column;
}

.contact-inputs-schedule-service {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.contact-inputs-column {
  width: 45%;
}

.dates-op {
  margin-bottom: 20px;
}

.current-service-label {
  margin: 0 10px;
}

.add-schedule-service-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0.6rem;
}

.label-text {
  margin: 10px;
  text-align: center;
}

.new-open-charge-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.same-day-checkbox {
  margin: auto;
  height: 1.5rem;
  width: 1.5rem;
}

.charge-datepicker {
  border: 0;
  border-radius: 10px;
  width: 120px;
  margin: 10px;
  text-align: center;
}

.schedule-service-time-select {
  width: 120px;
  height: 25px;
  font-size: 15px;
  background-color: #fff;
  border-radius: 10px;
  border: none;
  text-align-last: center;
  margin: 10px;
}

.schedule-service-time-select:focus {
  outline: none;
}

.btn-open-charge {
  margin-bottom: 20px;
  background-color: #fb9444;
  border: 0;
}

.datetime-open-charge {
  display: flex;
  justify-content: center;
}

.submit-open-charge {
  text-align: center;
  margin: 20px auto;
}

.schedule-personal-info-container {
  margin: auto;
}

.form-input-payment-info {
  border-color: rgba(61, 176, 216, 0.2);
  height: 55px;
  border-radius: 2rem;
  padding: 10px 20px;
}

.address-label {
  height: 45px;
  padding-left: 20px;
  font-size: 22px;
}

.google-adress-input {
    display: flex;
    flex-direction: column;
}

.google-adress-input  li {
  list-style: none;
}

.google-adress-input  li:hover {
  background: var(--main-up-keep);
}

.label-zip-code-warning {
  margin-top: 0.8rem;
}

.body-modal-container {
  display: flex;
  justify-content: space-between;
}

.link-input-modal {
  resize: none;
  text-overflow: ellipsis;
  width: 80%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.6);
  border-color: rgba(249, 151, 70, 1);
}

.link-input-modal:focus {
  box-shadow: var(--main-up-keep);
  outline: none;
}

.calendar-schedule-service-container {
  padding-bottom: 2em;
}

.calendar-schedule-service {
  border: 0;
  border-radius: 10px;
}

.schedule-serv-no-available-items-container{
  width: auto;
  text-align: center;
}
.schedule-serv-no-available-items-container > p{
  color: red;
}

.container-hour-open-charge{
  text-align: center;
}
@media (max-width: 999px) {
  .header-content-open-charge {
    margin: 0 5% 0 5%;
  }

  .same-day-div {
    padding-left: 0;
  }

  .add-open-charge-container {
    width: 50%;
  }

  .is-same-day-container {
    justify-content: center;
    flex-direction: column;
  }
}

.dropdown-schedule-service {
    background-color: var(--main-up-keep);
    border-color: var(--main-up-keep);
}

@media only screen and (max-width: 600px) {
  .charge-datepicker, .schedule-service-time-select, .label-text {
    margin: 10px 4px;
  }

  .container-inputs-open-charge {
    flex-direction: column;
  }

  .add-open-charge-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
